<template>
  <v-card>
    <v-card-title
      class="d-flex justify-space-between"
      >
      <div
        style="max-width: 90%"
        >
        <span>Editar {{ category.name }}</span>
      </div>
      <div>
        <v-icon
          @click="$emit('close')"
          color="primary"
          >
          mdi-close
        </v-icon>
      </div>
    </v-card-title>

    <v-card-text>
      <v-form
        v-model="valid"
        lazy-validation
        ref="form"
        >
        <v-text-field
          label="Nombre"
          v-model="attributes.name"
          filled
          :rules="[ v => !!v || 'Campo obligatorio.']"
          ></v-text-field>

        <v-text-field
          label="Descripción"
          v-model="attributes.description"
          filled
          ></v-text-field>
      </v-form>

      <v-divider></v-divider>

      <div
        class="text-right mt-3"
        >
        <v-btn
          text
          tile
          class="mr-3"
          color="primary"
          @click="$emit('close')"
          >
          Cancelar
        </v-btn>

        <v-btn
          tile
          color="primary"
          @click="editCategory"
          >
          Editar
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { EditCategory } from '@/graphql/mutations/category'

export default {
  data () {
    return {
      valid: true,
      attributes: {
        name: null,
        description: null,
      }
    }
  },

  props: {
    category: {
      required: true,
      type: Object
    }
  },

  mounted () {
    this.attributes.name = this.category.name
    this.attributes.description = this.category.description
  },

  methods: {
    editCategory () {
      if (this.valid) { this.valid = this.$refs.form.validate() }

      if (this.valid) {
        this.$apollo.mutate({
          mutation: EditCategory,
          variables: {
            input: {
              categoryId: this.category.id,
              attributes: this.attributes
            }
          }
        }).then( res => {
          this.$router.go()
        })
      }
    }
  }
}
</script>
