<template>
  <v-card
    v-if="parent"
    >
    <v-card-title
      class="d-flex justify-space-between"
      >
      <div
        style="max-width: 90%"
        >
        Eliminar categoría
      </div>
      <div>
        <v-icon
          @click="$emit('close')"
          color="primary"
          >
          mdi-close
        </v-icon>
      </div>
    </v-card-title>

    <v-card-text>
      <div>
        ¿Está seguro de que desea eliminar la categoría {{ parent.name }}? Esta acción <strong>no</strong> se puede deshacer.
      </div>

      <v-divider class="my-3"></v-divider>

      <div
        class="text-right"
        >
        <v-btn
          text
          tile
          class="mr-3"
          color="primary"
          @click="$emit('close')"
          >
          Cancelar
        </v-btn>

        <v-btn
          tile
          color="error"
          @click="deleteCategory"
          >
          Eliminar
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { DeleteCategory } from '@/graphql/mutations/category'

export default {
  props: {
    parent: {
      required: true,
      type: Object
    }
  },

  methods: {
    deleteCategory () {
      this.$apollo.mutate({
        mutation: DeleteCategory,
        variables: {
          input: {
            categoryId: this.parent.id
          }
        }
      }).then( res => {
        this.$router.go()
      })
    }
  }
}
</script>
