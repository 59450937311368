import gql from 'graphql-tag'

export const CreateCategory = gql`
  mutation createCategory($input: CreateCategoryInput!) {
    createCategory(input: $input) {
      success
    }
  }
`

export const EditCategory = gql`
  mutation editCategory($input: EditCategoryInput!) {
    editCategory(input: $input) {
      success
    }
  }
`

export const DeleteCategory = gql`
  mutation deleteCategory($input: DeleteCategoryInput!) {
    deleteCategory(input: $input) {
      success
    }
  }
`
