<template>
  <v-card
    class="mt-3"
    >
    <v-dialog
      width="800px"
      v-model="modal"
      :fullscreen="$vuetify.breakpoint.mobile"
      >
      <Create
        v-if="createModal"
        @close="modal = false"
        :parent="parent"
        />

      <Edit
        v-if="editModal"
        @close="modal = false"
        :category="parent"
        />

      <Delete
        v-if="deleteModal"
        @close="modal = false"
        :parent="parent"
        />
    </v-dialog>
    <v-sheet class="pa-4 primary">
      <v-text-field
        v-model="search"
        label="Buscar categoría"
        dark
        flat
        solo-inverted
        hide-details
        clearable
        clear-icon="mdi-close-circle-outline"
      ></v-text-field>
    </v-sheet>
    <v-card-text>
      <v-treeview
        :items="categories"
        :search="search"
        :filter="filter"
        open-on-click
        rounded
        >
        <template v-slot:prepend="{ item, open }">
          <v-icon>
            {{ open ? 'mdi-folder-open' : 'mdi-folder' }}
          </v-icon>
        </template>

        <template v-slot:label="{ item }">
          {{ item.name }}
        </template>

        <template v-slot:append="{ item }">
          <v-btn
            icon
            color="red darken-3"
            class="mr-1"
            @click.stop="openDelete(item)"
            >
            <v-icon>
              mdi-delete
            </v-icon>
          </v-btn>

          <v-btn
            icon
            color="primary"
            @click.stop="openEdit(item)"
            >
            <v-icon>
              mdi-pencil
            </v-icon>
          </v-btn>

          <v-btn
            icon
            color="primary"
            v-if="item.treeLevel < 2"
            @click.stop="openCreate(item)"
            >
            <v-icon>
              mdi-plus
            </v-icon>
          </v-btn>
        </template>
      </v-treeview>
    </v-card-text>
  </v-card>
</template>

<script>
import Create from './Create'
import Delete from './Delete'
import Edit from './Edit'

export default {
  data () {
    return {
      search: null,
      caseSensitive: false,
      modal: false,
      deleteModal: false,
      createModal: false,
      editModal: false,
      parent: null,
    }
  },

  props: {
    categories: {
      required: true,
      type: Array
    } 
  },

  components: {
    Create,
    Delete,
    Edit
  },

  computed: {
    filter () {
      return this.caseSensitive
        ? (item, search, textKey) => item[textKey].indexOf(search) > -1
        : undefined
    },
  },

  watch: {
    modal (val) {
      if (!val) {
        this.parentId = null
        this.deleteModal = val
        this.editModal = val
        this.createModal = val
      }
    }
  },

  methods: {
    openDelete (parent) {
      this.parent = parent
      this.modal = true
      this.deleteModal = true
    },

    openCreate (parent) {
      this.parent = parent
      this.modal = true
      this.createModal = true
    },

    openEdit (parent) {
      this.parent = parent
      this.modal = true
      this.editModal = true
    }
  }
}
</script>
