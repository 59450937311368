<template>
  <v-container
    style="max-width: 1200px"
    class="justify-center d-flex flex-column fill-width"
    v-if="currentUser"
    >
    <Header
      title="Categorías y subcategorías"
      >
      <v-spacer></v-spacer>

      <v-btn
        :tile="!$vuetify.breakpoint.mobile"
        :depressed="!$vuetify.breakpoint.mobile"
        :fab="$vuetify.breakpoint.mobile"
        :bottom="$vuetify.breakpoint.mobile"
        :fixed="$vuetify.breakpoint.mobile"
        :right="$vuetify.breakpoint.mobile"
        color="primary"
        @click.stop="openCreate"
        >
        <v-icon
          v-if="$vuetify.breakpoint.mobile"
          >
          mdi-plus
        </v-icon>
        <span
          v-else
          >
          Crear
        </span>
      </v-btn>
    </Header>
    
    <div
      >
      <List
        ref="list"
        :categories="categories"
        />
    </div>
  </v-container>
</template>

<script>
import Header from '@/components/admin/shared/Header'
import List from '@/components/admin/categories/List'
import { Categories } from '@/graphql/queries/categories'
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      categories: []
    }
  },

  computed: {
    ...mapGetters(['currentUser'])
  },

  components: {
    Header,
    List
  },

  watch: {
    currentUser () {
      this.fetchCategories()
    }
  },

  mounted () {
    if (this.currentUser) {
      this.fetchCategories()
    }
  },

  methods: {
    fetchCategories () {
      this.$apollo.query({
        query: Categories,
        variables: {
          storeId: this.currentUser.store.id,
          onlyRoot: true,
        }
      }).then( res => {
        this.categories = res.data.categories
      })
    },

    openCreate () {
      this.$refs.list.openCreate(null)
    },
  }
}
</script>
