<template>
  <v-card>
    <v-card-title
      class="d-flex justify-space-between"
      >
      <div
        style="max-width: 90%"
        >
        <span v-if="parent">Crear subcategoría de {{ parent.name }}</span>
        <span v-else>Crear categoría</span>
      </div>
      <div>
        <v-icon
          @click="$emit('close')"
          color="primary"
          >
          mdi-close
        </v-icon>
      </div>
    </v-card-title>

    <v-card-text>
      <v-form
        v-model="valid"
        lazy-validation
        ref="form"
        >
        <v-text-field
          label="Nombre"
          v-model="category.name"
          filled
          :rules="[ v => !!v || 'Campo obligatorio.']"
          ></v-text-field>

        <v-text-field
          label="Descripción"
          v-model="category.description"
          filled
          ></v-text-field>
      </v-form>

      <v-divider></v-divider>

      <div
        class="text-right mt-3"
        >
        <v-btn
          text
          tile
          class="mr-3"
          color="primary"
          @click="$emit('close')"
          >
          Cancelar
        </v-btn>

        <v-btn
          tile
          color="primary"
          @click="createCategory"
          >
          Crear
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { CreateCategory } from '@/graphql/mutations/category'

export default {
  data () {
    return {
      valid: true,
      category: {
        name: null,
        description: null,
        parentId: null,
      }
    }
  },

  props: {
    parent: {
      required: false,
      default: null,
      type: Object
    }
  },

  mounted () {
    if (this.parent) {
      this.category.parentId = this.parent.id
    }
  },

  methods: {
    createCategory () {
      if (this.valid) { this.valid = this.$refs.form.validate() }

      if (this.valid) {
        this.$apollo.mutate({
          mutation: CreateCategory,
          variables: {
            input: {
              attributes: this.category
            }
          }
        }).then( res => {
          this.$router.go()
        })
      }
    }
  }
}
</script>
